import React from "react";
import "./App.css";
import Navbar from "./Navbar";
import CssBaseline from "@mui/material/CssBaseline";
import Hero from "./Hero";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Projects from "./Projects";
import Contact from "./Contact";

function App() {
  console.log(
    "Cards have a certain styling based on window width; if they do not look right in responsive view make sure to refresh the page :)"
  );
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#1976d2",
      },
    },
  });
  return (
    <div>
      <p>This site is currently under construction ⚙️</p>
    </div>
  );
}

export default App;
